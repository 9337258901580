import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import './TableStyle.css'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PaginationBar from './PaginationBar'
import TimeFormatter from '../newTimeformatter/TimeFormatter';
import SyncIcon from '@mui/icons-material/Sync';
import moment from 'moment';
import Hexapi from '../HexAPI/Hexapi';
import Swal from 'sweetalert2';
// import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import DoneIcon from '@mui/icons-material/Done';
// import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { autocompleteClasses } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import { Input } from "../HexInput/InputBox";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal, Spinner } from 'react-bootstrap';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Countrydata from '../../Comp/Countrydata';
import AllStates from '../../Comp/AllStates';
import whatsappnotification from '../../Comp/whatsappnotification';

var oldData = []


export default class Datatable extends Component {
    constructor() {
        super()
        this.audioRef = React.createRef()
        this.state = {
            rowData: [], isDataFound: false, currentPaginationPage: 0, showPaginationRows: 0,
            isShowSearchBox: false, searchValue: '', showaudio: false, Status: false, oldData: '',
            rowuserid: '', joinus: '', origin: '', origindata: [], joindata: [], membership: false,
            windowwidth: window.innerWidth, showCartModal: false, Cartdata: [],
            Orderid: -1, quotationpdffile: '', showquotationpdf: false, showLoading: false, CIFcharges: 0, Bankcharges: 0,
            Specialdiscount: [], servicecharge: [], packagingcharge: [], isDomestic: false, OpenQueryModal: false,
            productrowdata: [], ShowQuotationOption: false, OpenEditModal: false, userorigin: '', usertype: '', Productdata: [],
            rowcelldata: [], CartDataFound: false, UserID: -1, UserEmailId: "", UserAddress: "", UserConatctNumber: "", Cartquantity: [],
            ProductPrice: [], UserID: -1, countrycode: "", flatAddress: "", pincode: "", AreaAddress: "", Landmark: "", city: "",
            state: "",

        }
    }



    componentDidMount() {

        // let obj1 = {
        //     'query': `[dbo].[Seetech_Web_Proc_User_Details]@UserID='{0}'`,
        //     'queryArr': [`${sessionStorage.getItem('userid')}`]

        // }
        // Hexapi(obj1).then((res) => {
        //     // console.warn(res);
        //     if (res[""][0]) {
        //         this.setState({ userorigin: res[""][0].origin, usertype: res[""][0].usertype })
        //     }
        // });


        const updatedData = this.props.data.map((item) => (
            { ...item, ViewQuotation: item.ViewQuotation, Action: item.send, }
        ))
        this.setState({ rowData: updatedData, isDataFound: true })
        oldData = updatedData
        // this.setState({ rowData: this.props.data, isDataFound: true })
        // oldData = this.props.data
        this.props.pagination && this.setState({ showPaginationRows: this.props.pagination.rowsPerPage })

        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_ApprovalForm_GetData]`,
            'queryArr': []
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            this.setState({ joindata: res['usertype'], origindata: res["origin"] });
        })

        window.addEventListener('resize', this.handleWindowSize);

    }


    handleWindowSize = () => {
        this.setState({ windowwidth: window.innerWidth });
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSize);
    }



    getHeader(data) {
        const header = Object.keys(data[0])
        return header.map((s) => {
            // console.log(heading)
            {



                // if (s !== "recid") {
                if (s == "registeredcontactnumber") {
                    return <th>Registered Contact Number</th>
                }

                return (
                    <th>{s}</th>


                )
            }
        }
            // }
        )
    }
    clearSearch(e) {
        this.setState({ rowData: oldData, isDataFound: true, isShowSearchBox: false, searchValue: '' })
    }
    setDate(date) {
        if (date != undefined) {
            // return date.split(' ')[0]
            // console.log(date)
            var transformdate = date.split(".")[0];
            // console.log(transformdate)
            //   console.log(date);
            var exitdate = TimeFormatter(transformdate);
            // console.log(exitdate);
            var visitorinfo = this.state.visitorinfo
            //   console.log(visitorinfo)
            // console.log(this.state.visitorinfo)
            var newReverseDate =
                exitdate.ShiftTime +
                "," +
                exitdate.userSplitedDate[2] +
                "-" +
                exitdate.ShiftMonth +
                "-" +
                exitdate.CurYear.toString().slice(-2);
            // console.log(newReverseDate);

            return newReverseDate;
        }
    }


    SelectQuery = () => {
        // this.set
    }

    Rejectrequest = (userid, recid) => {
        Swal.fire({
            text: " Do you want to Reject the User ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_Order_AdminStatus]
                    @UserID='{0}',
                    @RecID ='{1}',
                    @Status='{2}'`,
                    'queryArr': [`${userid}`, `${recid}`, '0']
                }
                Hexapi(obj).then((res) => {
                    console.log(res)
                    this.props.callback(true)
                });
            }
        })
    }

    Approvedrequest = async(userid, recid,row) => {
        console.log(recid,"orderid")
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Order_GetData] @OrderID='{0}'`,
            'queryArr': [`${recid}`]
        }

        let data={}
        await  Hexapi(obj).then((res) => {
            console.log(res,"response")
            data=res && res[""] && res[""][0] ? res[""][0] : []
        })
        console.log(row,"row")
        const orderid=row.recid
        let date=new Date().toISOString().split('T')[0]
        const linkdata=`https://SeetechParts.com/SeetechQuotations/${sessionStorage.getItem('userid')}-${orderid}.pdf`
        const templateparams=[data.name,`${orderid}`,`${orderid}`,date,data.price,linkdata,linkdata]
        const campaign="order_approval_notification_utility"
        Swal.fire({
            text: " Are you sure to Approve User Order!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            customClass: {
                container: 'custom-swal-container',
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_Order_AdminStatus]
                    @UserID='{0}',
                    @RecID ='{1}',
                    @Status='{2}'`,
                    'queryArr': [`${userid}`, `${recid}`, '1']
                }
                Hexapi(obj).then((res) => {
                    console.log(res)
                    this.props.callback(true)
                }).then((resp)=>{
                    whatsappnotification(templateparams,campaign,row.username,row.contactnumber);
                })
            }
        });


    }



    getRowsData(data) {
        // console.log(data)
        var newTableData = [].concat(data)
        let newData = newTableData
        if (this.props.pagination) {
            newData = newTableData.filter((row, index) => {
                let rowPerPage = this.state.showPaginationRows < 0 ? newTableData.length : this.state.showPaginationRows
                let start = this.state.currentPaginationPage * rowPerPage
                let end = (this.state.currentPaginationPage + 1) * rowPerPage
                if (index >= start && index < end) return true
            })
        }
        return newData.map((row, i) => {
            return (
                <tr key={i} onClick={() => {
                    // this.setState({membership:true});
                   console.log(row,"row-cart")
                    this.props.onRowClick(row)
                    this.CartgetData(row.recid, row)
                    this.setState({ Orderid: row.recid, UserID: row.userid })
                    this.setState({ userorigin: row.origin, usertype: row.usertype,
                        Bankcharges: row.bankcharges, CIFcharges: row.cifcharges,
                        UserEmailId: row.emailaddress, UserConatctNumber: row.contactnumber,
                        UserAddress: row.useraddress,productrowdata: row, })
                    // console.warn(row);
                }} >

                    {
                        Object.keys(row).map((heading) => {
                            if (row[heading] == null) {
                                row[heading] = ''
                            }

                            if (typeof row[heading] != 'object' && heading != "adminstatus" && heading != "Action" && heading != "ViewQuotation") {
                                return <td key={row} dangerouslySetInnerHTML={{ __html: row[heading] }} />
                            }
                            else if (heading == "ViewQuotation") {
                                return (
                                    <td key={row}>
                                        <span onClick={(e) => { e.stopPropagation(); this.DownloadQuotation(row.userid, row.recid, row) }}>
                                            <img src="./pdf.png" alt="pdf Icon" style={{ height: '30px', width: '30px' }}></img>
                                        </span>
                                    </td>
                                )
                            }
                            else if (heading == "Action") {
                                console.log(row,"rowdata")
                                return <td key={row}>
                                    <div style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                        <span style={{ padding: '5px 10px', background: ' #000000', borderRadius: '10px', color: 'white' }}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if (row.origin == "Domestic" || row.origin == "domestic") {
                                                    this.setState({ ShowQuotationOption: false })
                                                } else {
                                                    this.setState({ ShowQuotationOption: true })
                                                }
                                                this.setState({ Orderid: row.recid, UserID: row.userid })
                                                this.setState({
                                                    productrowdata: row, OpenQueryModal: true,
                                                    Bankcharges: row.bankcharges, CIFcharges: row.cifcharges,
                                                    UserEmailId: row.emailaddress, UserConatctNumber: row.contactnumber,
                                                    UserAddress: row.useraddress
                                                })

                                            }}>Send</span>

                                        <span onClick={(e) => {
                                            e.stopPropagation();
                                            this.setState({ rowcelldata: row });
                                            this.OpenEditFun(row.useraddress)
                                            this.setState({ CIFcharges: row.cifcharges, Bankcharges: row.bankcharges })
                                        }}>
                                            <EditIcon style={{color:'rgb(66 137 165)'}}/>
                                        </span>
                                    </div>
                                </td>
                            }
                            else if (heading == "adminstatus") {
                                if (row["userstatus"] == 1 && row["adminstatus"] == 0) {
                                    return (
                                        <td key={row}>
                                            <span onClick={(e) => { e.stopPropagation(); this.Approvedrequest(row["userid"], row['recid'],row); }}> <DoneIcon style={{color:'green'}}/> </span>
                                            <span onClick={(e) => { e.stopPropagation(); this.Rejectrequest(row["userid"], row["recid"]) }}> <CloseIcon style={{color:'red'}}/>  </span>
                                        </td>
                                    )
                                }
                                else if (row["userstatus"] == 0 && row["adminstatus"] == 0) {
                                    return <td key={row} >
                                        <span style={{ color: 'red' }}>
                                            Pending
                                        </span>
                                    </td>
                                }
                                else {
                                    return <td key={row} >
                                        <span style={{ color: 'green' }}>
                                            Approved
                                        </span>
                                    </td>
                                }
                            }
                            // else if (heading == "status") {
                            //     if (row["status"] == 1) {
                            //         return <td key={row} > Approved</td>
                            //     }
                            //     else {
                            //         return <td key={row}>Pending</td>
                            //     }
                            // }
                            else {
                                return <td key={row} dangerouslySetInnerHTML={{ __html: this.setDate(row[heading].date) }} />
                            }
                        }
                        )
                    }
                </tr>
            )
        })
    }

    OpenEditFun = (Address) => {
        let splitAdd = Address.split(",");
        console.log(splitAdd);
        this.setState({
            countrycode: splitAdd[6], flatAddress: splitAdd[0], pincode: splitAdd[1],
            AreaAddress: splitAdd[2], Landmark: splitAdd[3], city: splitAdd[4], state: splitAdd[5]
        })
        this.setState({ OpenEditModal: true })
    }

    storeSearch(e) {
        // console.log(oldData)
        let value = e.target.value
        this.setState({ searchValue: value })
        let applyFilter = Object.keys(oldData[0])

        // console.log(value)
        // console.log(applyFilter)
        if (value == '') {
            if (oldData.length > 0) {
                console.log(oldData)
                this.setState({ rowData: oldData, isDataFound: true })
                // console.log(rowData)
            } else {
                this.setState({ isDataFound: false })
            }
        } else {
            if ((oldData.filter((row) => {
                if (Array.isArray(applyFilter)) {
                    let StrArr = []
                    for (let i = 0; i < applyFilter.length; i++) {
                        StrArr.push(row[`${applyFilter[i]}`])
                        // console.log(StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase()))
                        // console.log(StrArr)
                    }
                    return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                } else {
                    return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())
                }
            })).length > 0) {
                this.setState({
                    rowData: (oldData.filter((row) => {
                        if (Array.isArray(applyFilter)) {
                            let StrArr = []
                            for (let i = 0; i < applyFilter.length; i++) {
                                StrArr.push(row[`${applyFilter[i]}`])
                                // console.log(StrArr)
                            }

                            return StrArr.join(' ').toString().toLowerCase().includes(value.toLowerCase())
                            // console.log(StrArr)
                        } else {
                            return (oldData[`${applyFilter[0]}`]).toString().toLowerCase().includes(value.toLowerCase())()
                            // console.log(StrArr)
                        }
                    }))
                    , isDataFound: true
                }, () => {
                    this.setState({ currentPaginationPage: 0 })
                })


            } else {
                this.setState({ isDataFound: true })
            }
        }
    }


    DeleteItemCart = (Id) => {
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Cart_Remove] @UserID='{0}',@ProductID='{1}'`,
            'queryArr': [`${this.state.UserID}`, `${Id}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res);
            this.CartgetData(this.state.Orderid)
        });
    }

    notify = () =>
        toast.success('Successfully Cart data Updated!', {
            position: toast.POSITION.TOP_CENTER, autoClose: 500
        });


    DownloadQuotation = (userid, recid, row) => {
        console.log(userid, recid);

        let Arr = {
            "Userid": userid,
            "Orderid": recid
        }
        let fd = new FormData();
        fd.append('dataArray', JSON.stringify(Arr));

        axios({
            mode: 'cors',
            method: "POST",
            headers: { "Content-Type": "multipart/form-data" },
            url: `${sessionStorage.getItem('Apipathurl')}Quotationdownload.php`,
            data: fd,
            responseType: 'arraybuffer',
        })
            .then((res) => {
                console.log(res);
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(pdfBlob);
                link.download = `SeetechtechQuoation-${userid}-${recid}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
    }


    CartgetData = (Id, row) => {
        // console.log(this.state.userorigin,this.state.usertype);
        this.setState({ showCartModal: true, showLoading: true })
        if (row) {
            if (row.origin == "domestic" || row.origin == "Domestic" || row.origin != undefined) {
                this.setState({ isDomestic: true })
            } else {
                this.setState({ isDomestic: false })

            }

        }
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Order_GetData] @OrderID='{0}'`,
            'queryArr': [`${Id}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            if (res[""].length > 0) {
                let data = res[""];
                console.log(data)
                let totalprice = 0;
                let Quantities = [];
                let itemprice = [];
                data.map((s) => {
                    // let price = undefined;
                    // if (this.state.userorigin.toLowerCase() === "domestic") {
                    //     if (this.state.usertype.toLowerCase() === "visitor") {

                    //     } else if (this.state.usertype.toLowerCase() === "retailer") {
                    //         price = `${Number(s.mrp).toFixed(2)}`;
                    //     } else {
                    //         price = `${Number(s.wholesaleprice).toFixed(2)}`;
                    //     }
                    // } else {
                    //     price = `${Number(s.overseasprice).toFixed(2)}`;
                    // }

                    Quantities.push(s.qty);
                    itemprice.push(s.price);


                })
                console.log(itemprice)
                this.setState({ showLoading: false, Cartquantity: Quantities, ProductPrice: itemprice })

                this.setState({ Totalprice: totalprice, Cartdata: res[""], CartDataFound: false })


            }
            else {
                this.setState({ showLoading: false })
                this.setState({ CartDataFound: true })
            }

        })
    }


    QuantityChange = (Id, Quantity) => {
        // let obj = {
        //     'query': `[dbo].[Seetech_Web_Proc_Cart_Quantity_OnChange] @UserID='{0}',@ProductID='{1}',@Qty='{2}'`,
        //     'queryArr': [`${sessionStorage.getItem('userid')}`, `${Id}`, `${Quantity}`]
        // }
        // Hexapi(obj).then((res) => {
        //     this.CartgetData(this.state.Orderid)
        //     console.log(res);
        // });
    }

    ChangeProductdata = (Id, index, service, packagecharge, discount, userid) => {
        console.log(Id);
        // this.Rejectrequest(userid, Id);
        console.log(this.state.Specialdiscount[index], this.state.servicecharge[index]);
        let servicecharge = this.state.servicecharge[index];
        let packing = this.state.packagingcharge[index];
        let specialdiscount = this.state.Specialdiscount[index];

        if (servicecharge == undefined) {
            servicecharge = service;
        }

        if (packing == undefined) {
            packing = packagecharge;
        }

        if (specialdiscount == undefined) {
            specialdiscount = discount;
        }

        const productvalue = Number(this.state.ProductPrice[index]).toFixed(2);
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Order_Details_ChangeData] @OrderDetailID='{0}',@Qty='{1}',@Price='{2}',@ServiceCharge='{3}', @PackingCharge='{4}',@SpecialDiscount='{5}'`,
            'queryArr': [`${Id}`, `${this.state.Cartquantity[index]}`, `${productvalue}`, `${servicecharge}`, `${packing}`, `${specialdiscount}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res);
            this.notify();
            this.CartgetData(this.state.Orderid)
            if(this.state.userorigin.toLowerCase()=="domestic"){
                this.OpenQuotation2(this.state.productrowdata, "inr");
            }else{
                this.OpenQuotation2(this.state.productrowdata, "usd");
            }
        });

        let obj1 = {
            'query': `[dbo].[Seetech_Web_Proc_Order_UserStatus]
            @UserID='{0}', @RecID ='{1}',@Status='{2}'`,
            'queryArr': [`${this.state.UserID}`, `${this.state.Orderid}`, '0']
        }
        Hexapi(obj1).then((res) => {
            console.log(res)
            // this.props.callback(true)
        });
    }

    OpenQuotation = (rowData, Originvalue) => {
        this.setState({ isDataFound: false });
        var productitem = [];
        var Totalprice = 0;
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Order_GetData] @OrderID='{0}'`,
            'queryArr': [`${rowData.recid}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            if (res[""].length >= 0) {
                let data = res[""];
                console.log(data)
                let totalprice = 0;
                let Quantities = [];
                data.map((s) => {
                    Quantities.push(s.qty);
                })
                console.log(totalprice)
                Totalprice = totalprice;
                productitem = res[""];
                // this.setState({ Totalprice: totalprice, Cartdata: res[""] })

            }
        }).then(() => {
            let arr = [];
            productitem.forEach((value) => { // Loop through your data array
                let GST = 0;
                let price = 0;
                let ServiceCharge = 0;
                let SpecialDiscount = 0;
                let PackingForwarding = 0;
                if (rowData.origin.toLowerCase() === "domestic" || Originvalue == "inr") {
                    if (rowData.usertype.toLowerCase() == "retailer") {
                        price = Number(value.mr).toFixed(2);
                        GST = Number(value.mrp).toFixed(2) * value.gst / 100;
                    } else if (rowData.usertype.toLowerCase() == "wholesaler") {
                        price = Number(value.wholesaleprice).toFixed(2);
                        GST = Number(value.wholesaleprice).toFixed(2) * value.gst / 100;
                    }
                    else {
                        price = Number(value.mrp).toFixed(2);
                        GST = Number(value.mrp).toFixed(2) * value.gst / 100;
                    }
                } else {

                    if (Originvalue == "inr") {
                        price = value.overseasprice * value.conversionrate;
                        // console.log(price)
                    } else {
                        price = value.overseasprice;

                    }
                    // price = Number(value.overseasprice).toFixed(0);
                }

                let images = value.images;
                images = images.split(',');
                console.warn(images);
                // Create an object for each item and push it to the array
                arr.push({
                    Image: value.images == "" ? '' : 'https://seetechparts.com/' + images[0].split('wp-content/')[1], // Make sure you have the correct image variable
                    description: value.description,
                    Qty: (value.qty == undefined || !value.qty ? 1 : value.qty),
                    price: value.price,
                    Gst: GST,
                    GSTpercentage: value.gst,
                    Totalprice: rowData.usertype.toLowerCase() == "wholesaler" ? Number(value.wholesaleprice).toFixed(2) * value.qty : Number(value.mrp).toFixed(2) * value.qty,
                    hsnCode: value.hsncode,
                    modelReference: value.suitableforpartreference,
                    companyCode: value.companycode,
                    sku: value.sku,
                    productName: value.name,
                    pricetag: Originvalue == "inr" ? "Rs" : "$",
                    address: this.state.UserAddress,
                    emailID: this.state.UserEmailId,
                    ContactNo: this.state.UserConatctNumber,
                    UserID: this.state.UserID,
                    OrderId: this.state.Orderid,

                    conversionrate: value.conversionrate,
                    servicecharge: value.servicecharge == NaN ? 0.00 : Number(value.servicecharge).toFixed(2),
                    specialdiscount: value.specialdiscount == NaN ? 0.00 : Number(value.specialdiscount).toFixed(2),
                    packingforward: value.packingcharge == NaN ? 0.00 : Number(value.packingcharge).toFixed(2),
                    origin: rowData.origin.toLowerCase() === "domestic" ? "domestic" : "overseas",
                    cifcharges: this.state.Bankcharges,
                    bankcharges: this.state.CIFcharges,
                    // servicecharge: Number(value.servicecharge).toFixed(2),
                    // specialdiscount: Number(value.specialdiscount).toFixed(2),
                    // packingforward: Number(value.packingcharge).toFixed(2),
                    // origin: rowData.origin.toLowerCase(),
                    // cifcharges: value.cifcharges == undefined || value.cifcharges == null ? 0.00 : value.cifcharges,
                    // bankcharges: value.bankcharges == undefined || value.bankcharges == null ? 0.00 : value.cifcharges,
                });
            });


            console.warn(arr);
            let fd = new FormData();
            fd.append('dataArray', JSON.stringify(arr));

            console.warn(fd);
            axios({
                mode: 'cors',
                method: "POST",
                // headers: { "Content-Type": "application/json" },
                headers: { "Content-Type": "multipart/form-data" },
                // url: `https://hexbss.xyz/testenvironment/seetechApiDomestic.php`,
                url: Originvalue == "inr" ? `${sessionStorage.getItem('Apipathurl')}seetechINR.php` : `${sessionStorage.getItem('Apipathurl')}seetechUSD.php`,
                data: fd,
                responseType: 'arraybuffer',
            }).then((res) => {
                console.log(res);
                console.log(res.data);
                const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                // const pdfObjectURL = URL.createObjectURL(pdfBlob);
                // setshowquotationpdf(true);
                // this.setState({ quotationpdffile: pdfObjectURL, showquotationpdf: true })
                const link = document.createElement('a');

                // Set the href attribute to a Blob URL
                link.href = URL.createObjectURL(pdfBlob);

                // Set the download attribute with the desired filename
                link.download = `SeetechtechQuoation-${this.state.UserID}-${this.state.Orderid}`;

                // Append the link to the body
                document.body.appendChild(link);

                // Trigger a click on the link
                link.click();

                // Remove the link from the body
                document.body.removeChild(link);
                this.setState({ isDataFound: true });
            })
        })

    }

    OpenQuotation2 = (rowData, Originvalue) => {
        this.setState({ isDataFound: false });
        var productitem = [];
        var Totalprice = 0;
        let obj = {
            'query': `[dbo].[Seetech_Web_Proc_Order_GetData] @OrderID='{0}'`,
            'queryArr': [`${rowData.recid}`]
        }
        Hexapi(obj).then((res) => {
            console.log(res)
            if (res[""].length >= 0) {
                let data = res[""];
                console.log(data)
                let totalprice = 0;
                let Quantities = [];
                data.map((s) => {
                    Quantities.push(s.qty);
                })
                console.log(totalprice)
                Totalprice = totalprice;
                productitem = res[""];
                // this.setState({ Totalprice: totalprice, Cartdata: res[""] })

            }
        }).then(() => {
            let arr = [];
            productitem.forEach((value) => { // Loop through your data array
                let GST = 0;
                let price = 0;
                let ServiceCharge = 0;
                let SpecialDiscount = 0;
                let PackingForwarding = 0;
                if (rowData.origin.toLowerCase() === "domestic" || Originvalue == "inr") {
                    if (rowData.usertype.toLowerCase() == "retailer") {
                        price = Number(value.mr).toFixed(2);
                        GST = Number(value.mrp).toFixed(2) * value.gst / 100;
                    } else if (rowData.usertype.toLowerCase() == "wholesaler") {
                        price = Number(value.wholesaleprice).toFixed(2);
                        GST = Number(value.wholesaleprice).toFixed(2) * value.gst / 100;
                    }
                    else {
                        price = Number(value.mrp).toFixed(2);
                        GST = Number(value.mrp).toFixed(2) * value.gst / 100;
                    }
                } else {

                    if (Originvalue == "inr") {
                        price = value.overseasprice * value.conversionrate;
                        // console.log(price)
                    } else {
                        price = value.overseasprice;

                    }
                    // price = Number(value.overseasprice).toFixed(0);
                }

                let images = value.images;
                images = images.split(',');
                console.warn(images);
                // Create an object for each item and push it to the array
                arr.push({
                    Image: 'https://seetechparts.com/' + images[0].split('wp-content/')[1], // Make sure you have the correct image variable
                    description: value.description,
                    Qty: (value.qty == undefined || !value.qty ? 1 : value.qty),
                    price: value.price,
                    Gst: GST,
                    GSTpercentage: value.gst,
                    Totalprice: rowData.usertype.toLowerCase() == "wholesaler" ? Number(value.wholesaleprice).toFixed(2) * value.qty : Number(value.mrp).toFixed(2) * value.qty,
                    hsnCode: value.hsncode,
                    modelReference: value.suitableforpartreference,
                    companyCode: value.companycode,
                    sku: value.sku,
                    productName: value.name,
                    pricetag: Originvalue == "inr" ? "Rs" : "$",
                    address: this.state.UserAddress,
                    emailID: this.state.UserEmailId,
                    ContactNo: this.state.UserConatctNumber,
                    UserID: this.state.UserID,
                    OrderId: this.state.Orderid,

                    conversionrate: value.conversionrate,
                    servicecharge: value.servicecharge == NaN ? 0.00 : Number(value.servicecharge).toFixed(2),
                    specialdiscount: value.specialdiscount == NaN ? 0.00 : Number(value.specialdiscount).toFixed(2),
                    packingforward: value.packingcharge == NaN ? 0.00 : Number(value.packingcharge).toFixed(2),
                    origin: rowData.origin.toLowerCase() === "domestic" ? "domestic" : "overseas",
                    cifcharges: this.state.Bankcharges,
                    bankcharges: this.state.CIFcharges,


                    // servicecharge: Number(value.servicecharge).toFixed(2),
                    // specialdiscount: Number(value.specialdiscount).toFixed(2),
                    // packingforward: Number(value.packingcharge).toFixed(2),
                    // origin: rowData.origin.toLowerCase(),
                    // cifcharges: value.cifcharges == undefined || value.cifcharges == null ? 0.00 : value.cifcharges,
                    // bankcharges: value.bankcharges == undefined || value.bankcharges == null ? 0.00 : value.cifcharges,
                });
            });


            console.warn(arr);
            let fd = new FormData();
            fd.append('dataArray', JSON.stringify(arr));

            console.warn(fd);
            axios({
                mode: 'cors',
                method: "POST",
                // headers: { "Content-Type": "application/json" },
                headers: { "Content-Type": "multipart/form-data" },
                // url: `https://hexbss.xyz/testenvironment/seetechApiDomestic.php`,
                url: Originvalue == "inr" ? `${sessionStorage.getItem('Apipathurl')}seetechINR.php` : `${sessionStorage.getItem('Apipathurl')}seetechUSD.php`,
                data: fd,
                responseType: 'arraybuffer',
            }).then((res) => {
                console.log(res);
                console.log(res.data);
                // const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
                // // const pdfObjectURL = URL.createObjectURL(pdfBlob);
                // // setshowquotationpdf(true);
                // // this.setState({ quotationpdffile: pdfObjectURL, showquotationpdf: true })
                // const link = document.createElement('a');

                // // Set the href attribute to a Blob URL
                // link.href = URL.createObjectURL(pdfBlob);

                // // Set the download attribute with the desired filename
                // link.download = `SeetechtechQuoation-${this.state.UserID}-${this.state.Orderid}`;

                // // Append the link to the body
                // document.body.appendChild(link);

                // // Trigger a click on the link
                // link.click();

                // // Remove the link from the body
                // document.body.removeChild(link);
                this.setState({ isDataFound: true });
            })
        })

    }

    chargesnotify = () =>
        toast.success('Charges Updated successfully!', {
            position: toast.POSITION.TOP_CENTER, autoClose: 500
        });


    SubmitOtherCharges = () => {
        if (this.state.pincode.length == 6) {
            if (this.state.flatAddress != "" && this.state.AreaAddress != "" && this.state.city != "" && this.state.state != "" && this.state.Landmark != "" && this.state.countrycode != "") {
                const personAddress = `${this.state.flatAddress},${this.state.pincode},${this.state.AreaAddress},${this.state.Landmark},${this.state.city},${this.state.state},${this.state.countrycode}`;
                let obj = {
                    'query': `[dbo].[Seetech_Web_Proc_Order_ExtraCharges] @UserID='{0}',@RecID='{1}', @BankCharges='{2}',@CIFCharges='{3}',@UserAddress='{4}'`,
                    'queryArr': [`${this.state.rowcelldata.userid}`, `${this.state.rowcelldata.recid}`, `${this.state.Bankcharges}`, `${this.state.CIFcharges}`, `${personAddress}`]
                }
                Hexapi(obj).then((res) => {
                    console.log(res);
                    this.setState({ OpenEditModal: false })
                    this.props.callback(true);
                    if (res[""][0]?.command == 1) {
                        this.chargesnotify();
                    }
                });
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please fill all Address Required field',
                })
            }
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please fill pincode',
            })
        }
    }

    render() {
        // console.log(this.state.windowwidth)
        const boxstyle = this.state.windowwidth < 482 ?
            {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '75%',
                bgcolor: 'background.paper',
                // border: '2px solid #000',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
                margin: 'auto',
            } : {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                // border: '2px solid #000',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
            };
        // console.log(this.props.pagination)
        return (
            <>
                {/* dangerouslySetInnerHTML={__html: this.state.html} */}
                <div style={{ height: 'calc(78vh - 5px)' }}>


                    <div style={{ position: 'relative', height: "inherit" }}>
                        {
                            this.props.isNavbar &&
                            <div className="_tablenavbar">
                                <div className="_left">
                                    <span>{this.props.heading}</span>
                                </div>
                                <div className="_right">
                                    <Tooltip title="Refresh">
                                        <IconButton style={{ color: '#fff' }} onClick={() => { this.setState({ isDataFound: false }); this.props.callback(true); }} >
                                            <SyncIcon />
                                        </IconButton>
                                    </Tooltip>
                                    {/* <SyncIcon onClick={() => { this.props.callback(true); this.setState({ isDataFound: false }) }} /> */}

                                    <input type="text" id="searchBarFocus" autoFocus={true} value={this.state.searchValue} style={{ width: this.state.isShowSearchBox ? "250px" : '0px', padding: this.state.isShowSearchBox ? "6px 12px" : "6px 0px", marginLeft: this.state.isShowSearchBox ? "10px" : '0px' }} className="editable" placeholder='Search...' onChange={(e) => this.storeSearch(e)} />
                                    {
                                        this.state.isShowSearchBox ?
                                            <Tooltip title="Close Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.clearSearch()}>
                                                    <CloseIcon />
                                                </IconButton>
                                            </Tooltip>

                                            :
                                            <Tooltip title="Open Search">
                                                <IconButton style={{ color: '#fff' }} onClick={() => this.setState({ isShowSearchBox: true }, () => {
                                                    if (document.getElementById('searchBarFocus')) {
                                                        document.getElementById('searchBarFocus').focus()
                                                    }
                                                })}>
                                                    <SearchIcon />
                                                </IconButton>
                                            </Tooltip>

                                    }
                                    {/* <Tooltip title={'Filter'}>
                                    <IconButton style={{ color: '#fff' }}>
                                        <FilterAltIcon />
                                    </IconButton>
                                </Tooltip> */}
                                </div>
                            </div>
                        }
                        <Table id='_tableGrid' className={'table-hover'} responsive style={{ width: '100%' }} >
                            <thead style={this.props.headStyle} >
                                {/* style={this.props.headStyle}} */}
                                <tr>
                                    {this.state.isDataFound &&
                                        this.state.rowData.length > 0 && this.getHeader(this.state.rowData)}
                                </tr>
                            </thead>
                            {

                                this.state.isDataFound ?
                                    this.state.rowData.length > 0
                                        ?
                                        <tbody>
                                            {
                                                this.getRowsData(this.state.rowData)
                                            }
                                        </tbody>
                                        :
                                        <tbody>
                                            <div style={{ marginTop: '10%' }}>
                                                <h3>No Data Found!</h3>
                                            </div>
                                        </tbody>
                                    :
                                    <tbody>
                                        <div style={{ marginTop: '10%' }}>
                                            <h3>
                                                <Spinner animation='border' style={{ color: '#f9b101' }} />
                                            </h3>
                                        </div>
                                    </tbody>

                            }
                        </Table>
                        {
                            this.props.pagination &&
                            this.state.isDataFound &&
                            <div className="_tablefooter">

                                <PaginationBar
                                    rowsPerPage={this.props.pagination.rowsPerPage}
                                    count={this.state.rowData.length}
                                    rowsPerPageOptions={this.props.pagination.rowsPerPageOptions}
                                    onPageChange={(currentPage) => this.setState({ currentPaginationPage: currentPage })}
                                    onRowsChange={(rows) => this.setState({ showPaginationRows: rows, currentPaginationPage: 0 })}
                                />
                            </div>
                        }

                    </div>
                </div>

                <Modal id="documentmodal" fullscreen={this.state.windowwidth < 1000 ? true : false} size={"lg"} centered animation={true} show={this.state.showCartModal} onHide={() => this.setState({ showCartModal: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Order Items</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='CartShopModal' style={this.state.windowwidth < 1000 ? { maxHeight: '100%' } : {}}>
                            {
                                this.state.CartDataFound ?
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5%', color: 'rgb(235 197 137)', minHeight: '200px' }}>
                                        <h3>No Data Found</h3>
                                    </div>
                                    :
                                    this.state.showLoading ?
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10%', color: 'rgb(235 197 137)', minHeight: '400px' }}>
                                            <h3>
                                                <Spinner animation='border' style={{ color: '#f9b101' }} />
                                            </h3>
                                        </div>

                                        :

                                        this.state.Cartdata.map((data, index) => {
                                            let images = data.images;
                                            images = images.split(',');

                                            let price = null;

                                            if (this.state.userorigin.toLowerCase() === "domestic") {
                                                if (this.state.usertype.toLowerCase() === "visitor") {
                                                    // Do nothing, or you can set a default value
                                                } else if (this.state.usertype.toLowerCase() === "retailer") {
                                                    price = `₹${Number(data.mrp).toFixed(2)}`;
                                                } else {
                                                    price = `₹${Number(data.wholesaleprice).toFixed(2)}`;
                                                }
                                            } else {
                                                // if (OverseasINR === true) {
                                                //     price = `Rs ${Number(data.overseasprice * data.conversionrate).toFixed(2)}`;
                                                // } else {
                                                price = `$${Number(data.overseasprice).toFixed(2)}`;
                                                // }
                                            }

                                            const priceSymbol = price.slice(0, 1);

                                            const pricevalue = price.slice(1);
                                            // console.log(priceSymbol, pricevalue);

                                            // console.log(this.state.ProductPrice[index],price.slice(1))
                                            return (

                                                <div className='CartItems' key={index}>
                                                    <div className='Cartimagestyle' >
                                                        <div style={{ width: '100%', display: 'flex' }}>
                                                            <img id="zoom1" src={data.images != "" ? `${images[0].replace('wp-content/',"")}`: `${sessionStorage.getItem('Apipathurl')}uploads/2023/11/dummy.png`} alt="image" className='CartImages'
                                                                style={this.state.windowwidth < 900 ? { width: '100%', height: '100%', margin: 'auto', cursor: 'pointer' } : { width: '80%', height: '80%', margin: 'auto', cursor: 'pointer' }} ></img>
                                                        </div>
                                                    </div>
                                                    <div style={{ width: '100%' }} >
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <h5 style={{ fontWeight: '400 !important' }}>{data.name}</h5>
                                                        </div>
                                                        <div>
                                                            <h6 style={{ fontSize: '16px', marginBottom: '0' }}>Description</h6>
                                                            <div>
                                                                <span style={{ color: '#817575', fontSize: '0.8rem', fontWeight: '500' }}>{data.description}</span>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <span>by : {data.brand}</span>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '5px', alignItems: 'center', fontWeight: '600', fontSize: '1.3rem' }}>
                                                            {/* {
                                                                this.state.usertype == undefined || this.state.usertype == null || this.state.usertype == "" ?
                                                                    null
                                                                    :
                                                                    price
                                                            } */}
                                                            <span style={{ fontSize: '0.9rem' }}>{priceSymbol}</span>
                                                            <input style={{ width: '70px', fontSize: '0.9rem', height: '21px', paddingLeft: '5px', border: 'none', borderBottom: '1.5px solid black' }} type='number'
                                                                value={this.state.ProductPrice[index] == null ? pricevalue : this.state.ProductPrice[index]}
                                                                onChange={(e) => {
                                                                    const updatedPrice = [...this.state.ProductPrice];
                                                                    updatedPrice[index] = e.target.value;
                                                                    this.setState({ ProductPrice: updatedPrice }, () => {
                                                                        // console.log(updatedPrice)
                                                                    })
                                                                }} placeholder='Price..' />

                                                            <span style={{ textDecoration: 'line-through', fontSize: '16px', }}>
                                                                {
                                                                    // this.state.usertype == undefined || this.state.usertype == null || this.state.usertype == "" ?
                                                                    //     null
                                                                    //     :
                                                                    this.state.userorigin.toLowerCase() === "domestic" ? (
                                                                        this.state.usertype.toLowerCase() == "wholesaler" &&
                                                                        (
                                                                            `₹${Number(data.mrp).toFixed(2)}`
                                                                        )
                                                                    ) : null
                                                                }

                                                            </span>
                                                        </div>

                                                        <div style={{}}>
                                                            <span style={{ color: 'rgb(208, 132, 12)', fontWeight: 'bold' }}>In stock</span>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'space-between' }}>
                                                            <span>Eligible for shipping</span>
                                                        </div>
                                                        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
                                                            <div style={{ cursor: 'pointer' }}>
                                                                <span>Qty: </span>
                                                                <input style={{ width: '50px', borderRadius: '10px', fontSize: '0.9rem', height: '21px', paddingLeft: '5px' }} type='number'
                                                                    value={data.qty != 0 && this.state.Cartquantity[index] == undefined ? data.qty : this.state.Cartquantity[index]}
                                                                    onChange={(e) => {
                                                                        const updatedCartQuantity = [...this.state.Cartquantity];
                                                                        updatedCartQuantity[index] = e.target.value;
                                                                        // setCartquantity(updatedCartQuantity);
                                                                        this.setState({ Cartquantity: updatedCartQuantity }, () => {
                                                                            // console.log(updatedCartQuantity);
                                                                        })
                                                                        // this.QuantityChange(data.recid, index);
                                                                    }} placeholder='Qty..' />
                                                            </div>
                                                            {/* <div class="vl"></div>
                                                            <div>
                                                                <span style={{ fontSize: '0.8rem', cursor: 'pointer' }} onClick={() => { this.DeleteItemCart(data.recid) }}>Delete</span>
                                                            </div> */}
                                                        </div>
                                                        {/* {
                                                    this.state.isDomestic == true ? */}
                                                        <div style={this.state.windowwidth < 1000 ? { display: 'block', marginTop: '10px' } : { display: 'flex', gap: '20px', alignItems: 'center', fontSize: '0.7rem', marginTop: '10px' }}>
                                                            {/* <div class="vl"></div> */}
                                                            <div style={{ cursor: 'pointer' }}>
                                                                <span>Service Charge : &nbsp;
                                                                    {/*  */}
                                                                    <input type='number'
                                                                        value={this.state.servicecharge[index] == undefined ? data.servicecharge : this.state.servicecharge[index]}
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value;
                                                                            if (newValue.length <= 3) {
                                                                                this.setState((prevState) => {
                                                                                    // Create a new array with the updated element
                                                                                    const updatedCIFCharges = [...prevState.servicecharge];
                                                                                    updatedCIFCharges[index] = newValue;
                                                                                    return { servicecharge: updatedCIFCharges };
                                                                                });

                                                                            }
                                                                        }}
                                                                        style={{ width: '20%', border: '2px solid #ababab', borderRadius: '5px', padding: '1px 4px' }}></input> %
                                                                </span>
                                                            </div>
                                                            {this.state.windowwidth < 1000 ? <div style={{ marginTop: '10px' }}></div> : <div class="vl"></div>}
                                                            <div style={{ cursor: 'pointer' }}>
                                                                <span>Packing Charge : &nbsp;
                                                                    {/* {data.servicecharge}*/}
                                                                    <input type='number'
                                                                        value={this.state.packagingcharge[index] == undefined ? data.packingcharge : this.state.packagingcharge[index]}
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value;
                                                                            if (newValue.length <= 3) {
                                                                                this.setState((prevState) => {
                                                                                    // Create a new array with the updated element
                                                                                    const updatedCIFCharges = [...prevState.packagingcharge];
                                                                                    updatedCIFCharges[index] = newValue;
                                                                                    return { packagingcharge: updatedCIFCharges };
                                                                                });

                                                                            }
                                                                        }}
                                                                        style={{ width: '20%', border: '2px solid #ababab', borderRadius: '5px', padding: '1px 4px' }}></input> %
                                                                </span>

                                                            </div>
                                                            {this.state.windowwidth < 1000 ? <div style={{ marginTop: '10px' }}></div> : <div class="vl"></div>}
                                                            <div style={{ cursor: 'pointer' }}>
                                                                <span>Special Discount : &nbsp;
                                                                    {/* {data.servicecharge}  */}
                                                                    <input type='number'
                                                                        value={this.state.Specialdiscount[index] == undefined ? data.specialdiscount : this.state.Specialdiscount[index]}
                                                                        onChange={(e) => {
                                                                            const newValue = e.target.value;
                                                                            if (newValue.length <= 3) {
                                                                                this.setState((prevState) => {
                                                                                    // Create a new array with the updated element
                                                                                    const updatedCIFCharges = [...prevState.Specialdiscount];
                                                                                    updatedCIFCharges[index] = newValue;
                                                                                    return { Specialdiscount: updatedCIFCharges };
                                                                                });
                                                                            }
                                                                        }}
                                                                        style={{ width: '20%', border: '2px solid #ababab', borderRadius: '5px', padding: '1px 4px' }}></input> %
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* : */}
                                                        {/* // } */}
                                                        <div style={{ display: 'flex', gap: '20px', alignItems: 'center', fontSize: '0.7rem', justifyContent: 'flex-end', marginTop: '10px' }}>
                                                            <div style={{ cursor: 'pointer' }}>
                                                                <span onClick={() => { this.ChangeProductdata(data.recid, index, data.servicecharge, data.packingcharge, data.specialdiscount, data.userid) }}> <DoneAllIcon /> </span>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                            }
                        </div>

                    </Modal.Body>
                </Modal >


                <Modal
                    fullscreen={true}
                    size="xl"
                    scrollable={true}
                    // fullscreen
                    show={this.state.showquotationpdf}
                    // onHide={showquotationpdf}
                    keyboard={false}
                    backdrop="static"
                    style={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                    centered >
                    <Modal.Header closeButton onClick={() => { this.setState({ showquotationpdf: false }) }}>
                        <Modal.Title>Download PDF</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: 'flex', justifyContent: 'center', height: '98%' }}>
                            <iframe
                                src={this.state.quotationpdffile}
                                title="Download pdf"
                                // src={`data:application/pdf;base64,${btoa(String.fromCharCode(...new Uint8Array(this.state.quotationpdffile)))}`}
                                style={{ display: 'flex', justifyContent: 'center', height: '100%', width: '100%' }}></iframe>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal id="documentmodal" centered animation={true} show={this.state.OpenQueryModal} onHide={() => this.setState({ OpenQueryModal: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Origin Quotation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ display: 'flex', justifyContent: 'space-around', minHeight: '70px', alignItems: 'center' }}>
                            {
                                this.state.ShowQuotationOption == true ?
                                    <>
                                        <div>
                                            <span style={{ backgroundColor: '#00590e', padding: '10px 20px', borderRadius: '10px', color: 'white', cursor: 'pointer' }} onClick={() => { this.OpenQuotation(this.state.productrowdata, "inr"); this.setState({ OpenQueryModal: false }) }}>INR</span>
                                        </div>
                                        <div>
                                            <span style={{ backgroundColor: 'rgb(0 83 95)', padding: '10px 20px', borderRadius: '10px', color: 'white', cursor: 'pointer' }} onClick={() => { this.OpenQuotation(this.state.productrowdata, "usd"); this.setState({ OpenQueryModal: false }) }}>USD</span>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div>
                                            <span style={{ backgroundColor: '#00590e', padding: '10px 20px', borderRadius: '10px', color: 'white', cursor: 'pointer' }} onClick={() => { this.OpenQuotation(this.state.productrowdata, "inr"); this.setState({ OpenQueryModal: false }) }}>INR</span>
                                        </div>
                                    </>

                            }
                        </div>
                    </Modal.Body>
                </Modal>


                {/* CIF and Bank Charges */}
                <Modal id="documentmodal" centered animation={true} show={this.state.OpenEditModal} onHide={() => this.setState({ OpenEditModal: false })} style={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Other Charges</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div className='OrderPanelModal' >
                            <div >
                                <div>

                                    <label style={{ fontSize: '0.8rem' }}><b>Country</b><span style={{ color: 'red' }}> *</span></label>
                                </div>
                                <select className="signupinput" onChange={(e) => { this.setState({ countrycode: e.target.value }); console.log(e.target.value) }}
                                    style={{ height: '45px' }} value={this.state.countrycode} >
                                    <option value="">---Select----</option>
                                    {Countrydata.map((s, index) => {
                                        return (
                                            <option value={s.name}>
                                                {s.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div>
                                <label style={{ fontSize: '0.8rem' }}><b>Flat,House no,Company</b><span style={{ color: 'red' }}> *</span></label>
                                <input className='signupinput' type="text" placeholder='' value={this.state.flatAddress} onChange={(e) => { this.setState({ flatAddress: e.target.value }); }}></input>
                            </div>

                            <div>
                                <label style={{ fontSize: '0.8rem' }}><b>Pincode</b><span style={{ color: 'red' }}> *</span></label>
                                <input className='signupinput' type="number" placeholder='6 digits [0-9] PIN Code' value={this.state.pincode} onChange={(e) => { this.setState({ pincode: e.target.value }); }}></input>
                            </div>

                            <div>
                                <label style={{ fontSize: '0.8rem' }}><b>Area, Street,Sector</b><span style={{ color: 'red' }}> *</span></label>
                                <input className='signupinput' type="text" placeholder='' value={this.state.AreaAddress} onChange={(e) => { this.setState({ AreaAddress: e.target.value }); }}></input>
                            </div>

                            <div>
                                <label style={{ fontSize: '0.8rem' }}><b>Landmark</b><span style={{ color: 'red' }}> *</span></label>
                                <input className='signupinput' type="text" placeholder='' value={this.state.Landmark} onChange={(e) => { this.setState({ Landmark: e.target.value }); }}></input>
                            </div>

                            <div>
                                <label style={{ fontSize: '0.8rem' }}><b>Town/City</b><span style={{ color: 'red' }}> *</span></label>
                                <input className='signupinput' type="text" placeholder='' value={this.state.city} onChange={(e) => { this.setState({ city: e.target.value }); }}></input>
                            </div>

                            <div>
                                <label style={{ fontSize: '0.8rem' }}><b>State</b><span style={{ color: 'red' }}> *</span></label>
                                {
                                    this.state.countrycode == "India" ?
                                        <select className="signupinput" onChange={(e) => { this.setState({ state: e.target.value }); console.log(e.target.value) }}
                                            style={{ height: '45px' }} value={this.state.state} >
                                            <option value="">---Select----</option>
                                            {AllStates.map((s, index) => {
                                                return (
                                                    <option value={s.name}>
                                                        {s.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        :
                                        <input className='signupinput' type="text" placeholder='' value={this.state.state} onChange={(e) => { this.setState({ state: e.target.value }); }}></input>
                                }
                            </div>
                            <div >
                                {/* <div class="vl"></div> */}
                                <div >
                                    <Input
                                        type={"text"}
                                        label="CIF Charges (Rs)"
                                        placeholder=" Enter scheme name"
                                        required={true}
                                        labelStyle={{ fontWeight: "600" }}
                                        value={this.state.CIFcharges}
                                        onChange={(e) => { this.setState({ CIFcharges: e.target.value }) }}
                                    />
                                </div>
                                <div >
                                    <Input
                                        type={"text"}
                                        label="Bank Charges (Rs)"
                                        placeholder=" Enter scheme name"
                                        required={true}
                                        labelStyle={{ fontWeight: "600" }}
                                        value={this.state.Bankcharges}
                                        onChange={(e) => { this.setState({ Bankcharges: e.target.value }) }}
                                    />
                                </div>
                                <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                                    <span style={{ backgroundColor: 'Black', padding: '10px 20px', borderRadius: '10px', color: 'white', cursor: 'pointer' }} onClick={() => { this.SubmitOtherCharges() }}>Submit</span>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <ToastContainer
                // className="toasterstyle" 
                />
            </>

        )
    }
}
